import { LocaleMessages } from '@/types';
import en from './en';
import ka from './ka';
import kk from './kk';
import ky from './ky';
import ru from './ru';
import hy from './hy';

const messages: LocaleMessages = {
  en,
  ka,
  kk,
  ky,
  ru,
  hy,
};

export default messages;
