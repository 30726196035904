import { Messages } from '@/types';

const messages: Messages = {
  login: 'Вход',
  incorrectIdentifierOrPassword: 'Неправильный идентификатор или пароль',
  emailPhoneNumber: 'Email, номер телефона...',
  email: 'Email',
  password: 'Пароль',
  enterYourEmail: 'Введите свой email',
  enterYourPassword: 'Введите свой пароль',
  enterYourEmailPhoneNumber: 'Введите свой email, номер телефона...',
  next: 'Далее',
  resetPassword: 'Смена пароля',
  resetLinkSending: 'Отправка ссылки для смены пароля',
  newPasswordCreation: 'Создание нового пароля',
  newPasswordCreated: 'Новый пароль создан',
  expiredLink: 'Устаревшая ссылка',
  authorization: 'Авторизация',
  communications: 'Способы связи',
  identifiers: 'Идентификаторы',
  names: 'Псевдонимы',
  confirm: 'Подтвердить',
  error: 'Ошибка',
  logout: 'выйти',
  or: 'или',
  changeAccount: 'сменить аккаунт',
  newPasswordSetSuccessfully: 'Новый пароль успешно задан',
  sendPasswordResetLink: 'Отправить ссылку для смены пароля',
  weSentPasswordResetInformationTo: 'Информация для смены пароля отправлена на {0}',
  return: 'Вернуться',
  passwordMustBeAtLeastCharactersInLength: 'Пароль должен быть не меньше {0} символов',
  reset: 'Сменить',
  rememberMe: 'Запомнить меня',
  yourPasswordResetLinkHasExpired: 'Ваша ссылка на изменение пароля истекла',
  registration: 'Регистрация',
  atLeastOneWithTypeIsRequired: 'Хотя бы один {0} с типом "{1}" обязателен',
  communication: 'способ связи',
  identifier: 'идентификатор',
  name: 'псевдоним',
  selectIdentifier: 'Выберите идентификатор для отправки кода',
  code: 'Код',
  enterCode: 'Введите свой код',
  sendingNewCodeIsPossibleAfter: 'Отправка нового кода возможна через {0}',
  send: 'отправить',
  incorrectCode: 'Неправильный код',
  tooManyAttemptsCodeMustBeResent: 'Слишком много попыток, необходимо переотправить код',
  emailOrPhone: 'Email или номер телефона',
  enterEmailOrPhone: 'Введите свой email или номер телефона',
  twoFactorAuthentication: 'Двухфакторная аутентификация',
  autologin: 'Автовход',
  youInstructToSendInformationTo: 'Вы даете поручение на передачу следующей информации в "{0}"',
  loginWith: 'Войти через ЕСИА',
  pageNotFound: 'Страница не найдена',
  loading: 'Загрузка',
};

export default messages;
