/* eslint-disable import/prefer-default-export */
import { vueStore } from '@/store/index';
import { AuthorizationData, SavedPages } from '@/types';

export class Store {
  private store = vueStore;

  constructor() {
    Object.freeze(this);
  }

  getResetPasswordCode() {
    return this.store.state.resetPasswordCode;
  }

  setResetPasswordCode(code: string) {
    this.store.commit('setResetPasswordCode', code);
  }

  getLang() {
    return this.store.state.lang;
  }

  getClientLang() {
    return this.store.state.clientLang;
  }

  setLang(lang: string) {
    this.store.commit('setLang', lang);
  }

  getClientId() {
    return this.store.state.clientId;
  }

  setClientId(clientId: string) {
    this.store.commit('setClientId', clientId);
  }

  getSettings() {
    return this.store.state.settings;
  }

  setSettings(settings: AuthorizationData) {
    this.setLang(settings.lang);
    this.store.commit('authorize', settings);
  }

  savePage<K extends keyof SavedPages>(page: K, data: SavedPages[K]) {
    this.store.commit('savePage', { page, data });
  }

  getPage<K extends keyof SavedPages>(page: K) {
    return this.store.state.savedPages[page];
  }

  saveTemplate(type: string, data?: string | null) {
    return this.store.commit('saveTemplate', { type, data });
  }

  getTemplate(type: string) {
    return this.store.state.templates[type];
  }
}
