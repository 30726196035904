import { Errors } from '@/types';

const errors: Errors = {
  50.0004: 'Жарамсыз әрекеттер реттілігі',
  50.0005: 'Параметрлер жоқ немесе жарамсыз',
  50.0007: 'Кодты қолдану әрекеті тым көп',
  50.0008: 'Логин немесе құпиясөз дұрыс емес',
  50.0012: 'Код дұрыс емес',
  50.0013: 'Құпиясөзде кемінде {0} таңбалары болуы керек',
};

export default errors;
