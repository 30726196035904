import 'whatwg-fetch';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/index';
import { vueStore } from '@/store/index';
import 'formdata-polyfill';

Vue.config.productionTip = false;

new Vue({
  router,
  store: vueStore,
  render: (h) => h(App),
}).$mount('#app');
