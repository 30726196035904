/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-syntax */
import {
  PdsApi,
  AuthorizeResponse,
  LoginRequestPayload,
  LoginResponse,
  SendRpcRequestPayload,
  LogoutResponse,
  AllowRequestPayload,
  Redirect,
  IdentifierRequestPayload,
  UseRpcRequestPayload,
  CodeRequestPayload,
  Use2FaResponse,
  HttpClient,
  ClientIdRequestPayload,
  TemplateRequestPayload,
  StartResponse,
} from '@/types';

function createUrlSearchParams(data: unknown) {
  const params = new URLSearchParams();
  if (typeof data === 'object' && data !== null) {
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          params.append(key, String(item));
        }
      } else {
        params.append(key, String(value));
      }
    }
  }
  return params;
}

enum Endpoints {
  Authorize = '/authorize',
  Login = '/login',
  AutoLogin = '/auto-login',
  Send2faCode = '/send-2fa-code',
  Use2faCode = '/use-2fa-code',
  Logout = '/logout',
  Allow = '/allow',
  SendResetPasswordCode = '/send-reset-password-code',
  UseResetPasswordCode = '/use-reset-password-code',
  Logo = '/client/logo',
  Background = '/client/background',
  Template = '/client/template',
  Start = '/start',
  LoginWithLink = '/extauth1-link',
  Callback = '/callback',
}

export class Pds implements PdsApi {
  // eslint-disable-next-line no-useless-constructor
  constructor(private httpClient: HttpClient) {}

  getHttpClient() {
    return this.httpClient;
  }

  authorize(payload: URLSearchParams) {
    return this.httpClient.get<AuthorizeResponse>(Endpoints.Authorize, payload);
  }

  login(payload: LoginRequestPayload) {
    return this.httpClient.post<LoginResponse>(
      Endpoints.Login,
      createUrlSearchParams(payload),
    );
  }

  autoLogin(payload: IdentifierRequestPayload) {
    return this.httpClient.post<LoginResponse>(
      Endpoints.AutoLogin,
      createUrlSearchParams(payload),
    );
  }

  logout(payload: IdentifierRequestPayload) {
    return this.httpClient.post<LogoutResponse>(
      Endpoints.Logout,
      createUrlSearchParams(payload),
    );
  }

  allow(payload: AllowRequestPayload) {
    return this.httpClient.post<Redirect>(Endpoints.Allow, createUrlSearchParams(payload));
  }

  sendTwoFactorAuthenticationCode(payload: IdentifierRequestPayload) {
    return this.httpClient.postNoContent(
      Endpoints.Send2faCode,
      createUrlSearchParams(payload),
    );
  }

  useTwoFactorAuthenticationCode(payload: CodeRequestPayload) {
    return this.httpClient.post<Use2FaResponse>(
      Endpoints.Use2faCode,
      createUrlSearchParams(payload),
    );
  }

  sendResetPasswordCode(payload: SendRpcRequestPayload) {
    return this.httpClient.postNoContent(
      Endpoints.SendResetPasswordCode,
      createUrlSearchParams(payload),
    );
  }

  useResetPasswordCode(payload: UseRpcRequestPayload) {
    return this.httpClient.postNoContent(
      Endpoints.UseResetPasswordCode,
      createUrlSearchParams(payload),
    );
  }

  getLogoLink(payload: ClientIdRequestPayload) {
    return this.httpClient.buildUrl(Endpoints.Logo, createUrlSearchParams(payload));
  }

  getBackgroundLink(payload: ClientIdRequestPayload) {
    return this.httpClient.buildUrl(Endpoints.Background, createUrlSearchParams(payload));
  }

  template(payload: TemplateRequestPayload) {
    return this.httpClient.getText(Endpoints.Template, createUrlSearchParams(payload));
  }

  start(clientId?: string | null) {
    let params: URLSearchParams = createUrlSearchParams({});
    if (clientId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params = createUrlSearchParams({ client_id: clientId });
    }
    return this.httpClient.get<StartResponse>(Endpoints.Start, params);
  }

  getLoginWithLink() {
    return this.httpClient.get<Redirect>(Endpoints.LoginWithLink);
  }

  callback(payload: URLSearchParams) {
    return this.httpClient.get<LoginResponse>(Endpoints.Callback, payload);
  }
}
