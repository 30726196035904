/* eslint-disable import/prefer-default-export */
import HttpClient from '@/helpers/httpClient';

import { Pds } from '@/api/pds';

// import { PdsWithTemplate } from '@/api/pdsWithTemplate';

const { VUE_APP_PDS_API: BASE } = process.env;

export const pds = new Pds(new HttpClient(BASE));

// export const pds = new PdsWithTemplate(new HttpClient(BASE));
