import { Messages } from '@/types';

const messages: Messages = {
  login: 'Кирүү',
  incorrectIdentifierOrPassword: 'Туура эмес аныктагыч же сырсөз',
  emailPhoneNumber: 'Email, телефон номери...',
  email: 'Email',
  password: 'Сырсөз',
  enterYourEmail: 'Өз Email почтаңызды киргизиңиз',
  enterYourPassword: 'Өз сырсөзүңүздү киргизиңиз',
  enterYourEmailPhoneNumber: 'Өз Email почтаңызды, телефон номериңизди киргизиңиз...',
  next: 'Кийинки',
  resetPassword: 'Сырсөздү өзгөртүү',
  resetLinkSending: 'Сырсөзүңүздү өзгөртүү үчүн шилтеме жөнөтүлүүдө',
  newPasswordCreation: 'Жаңы сырсөз түзүү',
  newPasswordCreated: 'Жаңы сырсөз түзүлдү',
  expiredLink: 'Эскирген шилтеме',
  authorization: 'Авторизациялоо',
  communications: 'Байланыш ыкмалары',
  identifiers: 'Аныктагычтар',
  names: 'лакап аттар',
  confirm: 'Ырастоо',
  error: 'Ката',
  logout: 'чыгуу',
  or: 'же',
  changeAccount: 'аккаунтту өзгөртүү',
  newPasswordSetSuccessfully: 'Жаңы сырсөз ийгиликтүү коюлду',
  sendPasswordResetLink: 'Сырсөздү өзгөртүү үчүн шилтемени жөнөтүңүз',
  weSentPasswordResetInformationTo: 'Сырсөздү өзгөртүү үчүн маалымат {0} ге жөнөтүлдү',
  return: 'Кайтуу',
  passwordMustBeAtLeastCharactersInLength: 'Сырсөз {0} белгиден кем болбошу керек',
  reset: 'өзгөртүү',
  rememberMe: 'Мени эсте',
  yourPasswordResetLinkHasExpired: 'Сырсөздү өзгөртүү шилтемеси бүттү',
  registration: 'каттоо',
  atLeastOneWithTypeIsRequired: '{1} түрү менен жок дегенде бир {0} милдеттүү',
  communication: 'байланыш ыкмасы',
  identifier: 'Аныктагыч',
  name: 'ысым',
  selectIdentifier: 'Кодду жөнөтүү үчүн аныктагычты тандаңыз',
  code: 'Код',
  enterCode: 'Кодуңузду киргизиңиз',
  sendingNewCodeIsPossibleAfter: 'Жаңы кодду {0} аркылуу жөнөтүү мүмкүн',
  send: 'жөнөтүү',
  incorrectCode: 'Туура эмес код',
  tooManyAttemptsCodeMustBeResent: 'Аракет өтө көп, кодду кайра жөнөтүү керек',
  emailOrPhone: 'Email почта же телефон номери',
  enterEmailOrPhone: 'Email почтаңызды же телефон номериңизди киргизиңиз',
  twoFactorAuthentication: 'Эки факторлуу аутентификация',
  autologin: 'Автокирүү',
  youInstructToSendInformationTo: 'Сиз төмөнкү маалыматты "{0}" дарегине жөнөтүүнү тапшырасыз',
  loginWith: 'ESIA менен кирүү',
  pageNotFound: 'Барак табылган жок',
  loading: 'жүктөө',
};

export default messages;
