/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-useless-constructor */
/* eslint-disable import/prefer-default-export */
import { Store } from '@/store/store';
import {
  PdsApi, PageTemplatesData, MustacheCompiler,
} from '@/types';
import { pds } from '@/api';
import DomPurify from 'dompurify';
import Mustache from 'mustache';

export class PageTemplate {
  constructor(
    private store: Store = new Store(),
    private pdsApi: PdsApi = pds,
    private sanitizer = DomPurify,
    private compiler: MustacheCompiler = Mustache,
  ) {
    this.sanitizer.setConfig({
      WHOLE_DOCUMENT: true,
      FORBID_TAGS: ['link', 'style'],
      ADD_TAGS: ['meta'],
      ADD_ATTR: ['content', 'charset'],
    });
  }

  async fetch(type: string, lang: string, clientId: string) {
    const template = this.get(type);
    if (template || template === null) return;
    const result = await this.pdsApi.template({
      type,
      lang,
      client_id: clientId,
    });
    if (result.isSuccess()) {
      this.store.saveTemplate(type, result.value);
    } else {
      this.store.saveTemplate(type, null);
    }
  }

  get(type: string) {
    return this.store.getTemplate(type);
  }

  has(type: string) {
    return this.store.getTemplate(type) != null;
  }

  render<T extends keyof PageTemplatesData>(type: T, data: PageTemplatesData[T]) {
    const template = this.get(type);
    if (template) {
      const compiledTemplate = this.compiler.render(template, data);
      return this.sanitizer.sanitize(compiledTemplate);
    }
    throw new Error(`The template for ${type} is not defined`);
  }
}
