/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import { Pages } from '@/types';
import NotFound from '@/views/NotFound.vue';
import { I18n } from '@/i18n/i18n';
import { authorization } from '@/helpers/authorization';
import { Store } from '@/store/store';
import { PageTemplate } from '@/helpers/pageTemplate';
import { App } from '@/helpers/app';
import { appSession } from '@/helpers/session';

Vue.use(VueRouter);

const store = new Store();
const i18n = new I18n(store);
const template = new PageTemplate(store);
const appSettings = new App(i18n);

async function fetchTemplate(type: string, lang: string, clientId: string) {
  const templates = [type, 'css', Pages.Error]
    .map((item) => template.fetch(item, lang, clientId));
  await Promise.all(templates);
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

interface RouteData {
  clientId: string | null;
  lang: string | null;
}

function parseRoute(route: Route) {
  const { client_id, lang } = route.query;
  const output: RouteData = {
    clientId: null,
    lang: null,
  };
  if (isString(client_id)) {
    output.clientId = client_id;
  }
  if (isString(lang)) {
    output.lang = lang;
  }
  return output;
}

async function setStateByRoute(state: RouteData) {
  if (state.clientId) {
    authorization.setClientId(state.clientId);
  }
  if (state.lang) {
    appSession.localize(state.lang);
  }
}

function resetQuery() {
  try {
    window.history.pushState(null, '', window.location.pathname);
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: Pages.NotFound,
    component: NotFound,
    meta: {
      auth: false,
      title: 'pageNotFound',
    },
  },
  {
    path: '/authorize',
    name: Pages.Authorize,
    component: () => import(/* webpackChunkName: 'authorize' */ '../views/Authorize.vue'),
    meta: {
      auth: false,
      title: 'authorization',
      layout: false,
      start: true,
    },
  },
  {
    path: '/callback',
    name: Pages.Callback,
    component: () => import(/* webpackChunkName: 'callback' */ '../views/Callback.vue'),
    meta: {
      auth: false,
      title: 'login',
      layout: false,
      start: false,
    },
  },
  {
    path: '/login',
    name: Pages.Login,
    component() {
      if (template.has(Pages.Login)) {
        return import(/* webpackChunkName: 'client-login' */ '../views/client/ClientLogin');
      }
      return import(/* webpackChunkName: 'login' */ '../views/Login.vue');
    },
    meta: {
      auth: true,
      title: 'login',
      start: false,
    },
  },
  {
    path: '/auto-login',
    name: Pages.AutoLogin,
    component() {
      if (template.has(Pages.AutoLogin)) {
        return import(/* webpackChunkName: 'client-auto-login' */ '../views/client/ClientAutoLogin');
      }
      return import(/* webpackChunkName: 'auto-login' */ '../views/AutoLogin.vue');
    },
    meta: {
      auth: true,
      title: 'autologin',
      start: false,
    },
  },
  {
    path: '/allow',
    name: Pages.Allow,
    component() {
      if (template.has(Pages.Allow)) {
        return import(/* webpackChunkName: 'client-allow' */ '../views/client/ClientAllow');
      }
      return import(/* webpackChunkName: 'allow' */ '../views/Allow.vue');
    },
    meta: {
      auth: true,
      title: 'authorization',
      start: false,
    },
  },
  {
    path: '/2fa',
    name: Pages.TwoFactorAuthentication,
    component() {
      if (template.has(Pages.TwoFactorAuthentication)) {
        return import(/* webpackChunkName: 'client-auth' */ '../views/client/ClientTwoFactorAuthentication');
      }
      return import(/* webpackChunkName: 'auth' */ '../views/TwoFactorAuthentication.vue');
    },
    meta: {
      auth: true,
      title: 'twoFactorAuthentication',
      start: false,
    },
  },
  {
    path: '/reset-password-link',
    name: Pages.SendResetPasswordCode,
    component() {
      if (template.has(Pages.SendResetPasswordCode)) {
        return import(/* webpackChunkName: 'client-reset-password-link' */ '../views/client/ClientResetPasswordLink');
      }
      return import(/* webpackChunkName: 'reset-password-link' */ '../views/ResetPasswordLink.vue');
    },
    meta: {
      auth: false,
      title: 'resetPassword',
      resetQuery: true,
      start: false,
    },
  },
  {
    path: '/reset-password',
    name: Pages.UseResetPasswordCode,
    component() {
      if (template.has(Pages.UseResetPasswordCode)) {
        return import(/* webpackChunkName: 'client-reset-password' */ '../views/client/ClientResetPassword');
      }
      return import(/* webpackChunkName: 'reset-password' */ '../views/ResetPassword.vue');
    },
    meta: {
      auth: false,
      title: 'newPasswordCreation',
      resetQuery: true,
      start: true,
    },
    beforeEnter(to, _, next) {
      const { code } = to.query;
      if (isString(code)) {
        store.setResetPasswordCode(code);
      }
      next();
    },
  },
  {
    path: '/error',
    name: Pages.Error,
    component() {
      if (template.has(Pages.Error)) {
        return import(/* webpackChunkName: 'client-error' */ '../views/client/ClientError');
      }
      return import(/* webpackChunkName: 'error' */ '../views/Error.vue');
    },
    meta: {
      auth: false,
      title: 'error',
      start: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  const routeData = parseRoute(to);
  if (to.meta.start === true || appSession.isPending()) {
    await appSession.start(routeData.clientId);
  } else {
    appSession.continue();
  }
  setStateByRoute(routeData);
  if (authorization.check() === false && to.meta.auth === true) {
    store.savePage(Pages.Error, {
      messages: ['Unauthorized'],
    });
    appSettings.setTitle('error');
    next({ name: Pages.Error });
  }
  const clientId = authorization.getClientId();
  if (to.name && clientId) {
    await fetchTemplate(to.name, i18n.getLang(), clientId);
  }
  const title = to.meta.title ?? 'loading';
  appSettings.setTitle(title);
  if (to.meta.layout === undefined) {
    if (template.has(String(to.name))) {
      // eslint-disable-next-line no-param-reassign
      to.meta.layout = false;
    } else {
      // eslint-disable-next-line no-param-reassign
      to.meta.layout = true;
    }
  }
  next();
});

router.afterEach((to) => {
  if (to.meta.resetQuery === true) {
    resetQuery();
  }
});

export default router;
