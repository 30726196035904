/* eslint-disable import/prefer-default-export */
import messages from '@/i18n/messages';
import errors from '@/i18n/errors';
import { Store } from '@/store/store';
import { ErrorCodes, ErrorResponse, MessageKeys } from '@/types';

export class I18n {
  constructor(private store: Store = new Store()) {
    Object.freeze(this);
  }

  private messages = messages;

  private errors = errors;

  private get localeMessages() {
    return this.messages[this.getLang()] || this.messages.en;
  }

  private get localeErrors() {
    return this.errors[this.getLang()] || this.errors.en;
  }

  getLang() {
    return this.store.getLang();
  }

  setLang(lang: string) {
    this.store.setLang(lang);
  }

  translate(string: MessageKeys, substitutions?: unknown[]) {
    return I18n.tryTranslateWithSubstitutions(this.localeMessages, string, substitutions);
  }

  translateError(error: ErrorResponse): ErrorResponse {
    if (error.code) {
      const { code } = error;
      if (this.hasError(code)) {
        const PASSWORD_MIN_LENGTH = 6;
        let substitutions: (string | number)[] = [];
        if (code === '50.0013') { // passwordMustIncludeAMinOf_0_Characters)
          substitutions = [PASSWORD_MIN_LENGTH];
        }
        const message = I18n.tryTranslateWithSubstitutions(this.localeErrors, code, substitutions);
        return {
          code,
          messages: [message],
        };
      }
    }
    return error;
  }

  private hasError(code: string): code is ErrorCodes {
    return code in this.localeErrors;
  }

  private static tryTranslateWithSubstitutions(
    dictionary: Record<string, string>,
    string: string,
    substitutions?: unknown[],
  ) {
    const pattern = I18n.tryTranslate(dictionary, string);
    if (substitutions && substitutions.length > 0) {
      return pattern.replace(/\{(\d)\}/g, (_, index: number) => String(substitutions[index]));
    }
    return pattern;
  }

  private static tryTranslate(dictionary: Record<string, string>, string: string): string {
    if (string in dictionary) {
      return dictionary[string];
    }
    return string;
  }
}
