import { Errors } from '@/types';

const errors: Errors = {
  50.0004: 'Недопустимая последовательность действий',
  50.0005: 'Параметры отсутствуют или недействительны',
  50.0007: 'Слишком много попыток применить код',
  50.0008: 'Неверный логин или пароль',
  50.0012: 'Неверный код',
  50.0013: 'Пароль должен содержать минимум {0} символов',
};

export default errors;
