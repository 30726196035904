/* eslint-disable no-useless-constructor */
/* eslint-disable import/prefer-default-export */
import { pds } from '@/api';
import {
  AuthorizationData, PdsApi, SessionData,
} from '@/types';
import { Publisher } from './publisher';

class Session extends Publisher<Partial<SessionData>> {
  static readonly STORAGE_SESSION_KEY = 'pds-auth-app-session';

  private state: Partial<SessionData> = this.getStateFromStorage() ?? {};

  constructor(
    private pdsApi: PdsApi = pds,
    private storage: Storage = sessionStorage,
  ) {
    super();
    Object.seal(this);
  }

  isPending() {
    return this.getStateFromStorage() == null;
  }

  reset() {
    this.removeStateFromStorage();
    this.state = {};
  }

  continue() {
    this.state = this.getStateFromStorage() ?? {};
    this.updateApiHeaders();
    this.notify(this.state);
  }

  getStartData() {
    return this.state.app;
  }

  async start(clientId?: string | null) {
    this.reset();
    const result = await this.pdsApi.start(clientId);
    if (result.isSuccess()) {
      this.state.app = result.value;
      this.state.lang = result.value.default_lang;
      const XSRFToken = this.pdsApi.getHttpClient().getHeader('X-CSRF-Token');
      if (XSRFToken) {
        this.state.XCSRFToken = XSRFToken;
      }
      this.saveStateToStorage();
      this.notify(this.state);
    }
  }

  authorize(client: AuthorizationData) {
    this.state.client = client;
    this.state.lang = client.lang;
    this.saveStateToStorage();
  }

  localize(lang: string) {
    this.state.lang = lang;
    this.saveStateToStorage();
    this.notify(this.state);
  }

  private getStateFromStorage() {
    const storageState = this.storage.getItem(Session.STORAGE_SESSION_KEY);
    if (storageState) {
      return JSON.parse(storageState) as Partial<SessionData>;
    }
    return null;
  }

  private saveStateToStorage() {
    this.storage.setItem(Session.STORAGE_SESSION_KEY, JSON.stringify(this.state));
  }

  private removeStateFromStorage() {
    this.storage.removeItem(Session.STORAGE_SESSION_KEY);
  }

  private updateApiHeaders() {
    if (this.state.XCSRFToken) {
      this.pdsApi.getHttpClient().setHeader('X-CSRF-Token', this.state.XCSRFToken);
    }
  }
}

export const appSession = new Session();
