/* eslint-disable import/prefer-default-export */
import { MessageKeys } from '@/types';
import { I18n } from '@/i18n/i18n';
import { appSession } from '@/helpers/session';

export class App {
  constructor(
    private i18n: I18n,
    private document = window.document,
    private session = appSession,
  ) {
    this.session.subscribe((state) => {
      if (state.lang) {
        this.setLang(state.lang);
      }
    });
  }

  getTitle() {
    return this.document.title;
  }

  setTitle(value: MessageKeys) {
    this.document.title = this.i18n.translate(value);
  }

  setLang(lang: string) {
    this.document.documentElement.lang = lang;
    this.i18n.setLang(lang);
  }
}
