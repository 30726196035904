/* eslint-disable import/prefer-default-export */
import router from '@/router';
import { Store } from '@/store/store';
import { Pages, ApiResponseResult, ErrorResponse } from '@/types';

export class Router {
  private router = router;

  // eslint-disable-next-line no-useless-constructor
  constructor(private store: Store = new Store()) {
    Object.freeze(this);
  }

  changePage(result: ApiResponseResult) {
    if (result.isFailure()) {
      this.showError(result.error);
      return;
    }
    const nextPage = result.value.page_to_show;
    if (this.isRedirect(nextPage)) {
      this.leave(nextPage);
    }
    if (result.value.payload) {
      this.store.savePage(nextPage, result.value.payload);
    }
    this.goTo(nextPage);
  }

  // eslint-disable-next-line class-methods-use-this
  private isRedirect(page: Pages): page is Pages.Redirect {
    return page.startsWith('redirect:');
  }

  goTo(name: Pages) {
    this.router.push({ name });
  }

  showError(error: ErrorResponse) {
    this.store.savePage(Pages.Error, error);
    this.goTo(Pages.Error);
  }

  // eslint-disable-next-line class-methods-use-this
  leave(page: Pages.Redirect): never {
    const error = new Error(`Incorrect redirect URL: ${page}`);
    const path = page.split('redirect:').pop();
    if (path === undefined) {
      throw error;
    }
    try {
      const url = new URL(path);
      return window.location.assign(url.toString()) as never;
    } catch (e) {
      throw error;
    }
  }
}
