/* eslint-disable import/prefer-default-export */
import { Subscriber } from '@/types';

export class Publisher<T> {
  private subscribers: Subscriber<T>[] = [];

  subscribe(subscriber: Subscriber<T>) {
    if (this.hasSubscriber(subscriber) === false) {
      this.subscribers.push(subscriber);
    }
  }

  unsubscribe(subscriber: Subscriber<T>) {
    this.removeSubscriber(subscriber);
  }

  private hasSubscriber(subscriber: Subscriber<T>) {
    return this.findSubscriber(subscriber) != null;
  }

  private findSubscriber(subscriber: Subscriber<T>) {
    const index = this.subscribers.indexOf(subscriber);
    if (index !== -1) {
      return this.subscribers[index];
    }
    return null;
  }

  private removeSubscriber(subscriber: Subscriber<T>) {
    const index = this.subscribers.indexOf(subscriber);
    if (index !== -1) {
      this.subscribers.splice(index, 1);
    }
  }

  protected notify(state: T) {
    this.subscribers.forEach((listener) => listener(state));
  }
}
