import { Messages } from '@/types';

const messages: Messages = {
  login: 'შესვლა',
  incorrectIdentifierOrPassword: 'არასწორი იდენტიფიკატორი ან პაროლი',
  emailPhoneNumber: 'ელ. ფოსტა ან ტელეფონის ნომერი...',
  email: 'Email',
  password: 'პაროლი',
  enterYourEmail: 'შეიყვანეთ თქვენი email',
  enterYourPassword: 'შეიყვანეთ თქვენი პაროლი',
  enterYourEmailPhoneNumber: 'შეიყვანეთ თქვენი ელ. ფოსტა ან ტელეფონის ნომერი...',
  next: 'შემდეგი',
  resetPassword: 'პაროლის აღდგენა',
  resetLinkSending: 'პაროლის აღდგენის ლინკის გაგზავნა',
  newPasswordCreation: 'ახალი პაროლის შექმნა',
  newPasswordCreated: 'ახალი პაროლი შექმნილია',
  expiredLink: 'ვადაგასული ბმული',
  authorization: 'ავტორიზაცია',
  communications: 'კავშირის სახეობები',
  identifiers: 'იდენტიფიკატორები',
  names: 'ფსევდონიმები',
  confirm: 'დადასტურება',
  error: 'შეცდომა',
  logout: 'გამოსვლა',
  or: 'ან',
  changeAccount: 'მომხმარებლის შეცვლა',
  newPasswordSetSuccessfully: 'ახალი პაროლი წარმატებით შეყვანილია',
  sendPasswordResetLink: 'პაროლის აღდგენის ლინკის გაგზავნა',
  weSentPasswordResetInformationTo: 'პაროლის შეცვლისთვის საჭირო ინფორმაცია გაგზავნილია {0}-ზე',
  return: 'დაბრუნება',
  passwordMustBeAtLeastCharactersInLength: 'პაროლი უნდა შედგებოდეს არანაკლებ {0} სიმბოლოსა',
  reset: 'შეცვლა',
  rememberMe: 'დამახსოვრება',
  yourPasswordResetLinkHasExpired: 'პაროლის ცვლილების თქვენს ბმულს გაუვიდა მოქმედების ვადა',
  registration: 'რეგისტრაცია',
  atLeastOneWithTypeIsRequired: 'აუცილებელია თუნდაც ერთი {0} ტიპით "{1}"',
  communication: 'კავშირის სახეობა',
  identifier: 'იდენტიფიკატორი',
  name: 'ფსევდონიმი',
  selectIdentifier: 'შეარჩიეთ იდენტიფიკატორი კოდის გაგზავნისთვის',
  code: 'კოდი',
  enterCode: 'შეიყვანეთ თქვენი კოდი',
  sendingNewCodeIsPossibleAfter: 'ახალი კოდის გაგზავნა შესაძლებელი {0} მეშვეობით',
  send: 'გაგზავნა',
  incorrectCode: 'არასწორი კოდი',
  tooManyAttemptsCodeMustBeResent: 'ძალიან ბევრი მცდელობა, აუცილებელია კოდის ხელახლა გაგზავნა',
  emailOrPhone: 'Email ან ტელეფონის ნომერი',
  enterEmailOrPhone: 'შეიყვანეთ თქვენი ელ.ფოსტა',
  twoFactorAuthentication: 'ორფაქტორული აუთენტიფიკაცია',
  autologin: 'ავტოშესვლა',
  youInstructToSendInformationTo: 'თქვენ იძლევით დავალებას შემდეგი ინფორმაციის "{0}"-ზე გადაცემისთვის',
  loginWith: '',
  pageNotFound: 'გვერდი არ მოიძებნა',
  loading: 'ჩატვირთვა',
};

export default messages;
