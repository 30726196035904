import { Errors } from '@/types';

const errors: Errors = {
  50.0004: 'Գործողությունների անընդունելի հաջորդականություն',
  50.0005: 'Պարամետրերը բացակայում են կամ անվավեր են',
  50.0007: 'Ծածկագրի կիրառման չափազանց շատ փորձեր',
  50.0008: 'Սխալ լոգին կամ գաղտնաբառ',
  50.0012: 'Սխալ գաղտնաբառ',
  50.0013: 'Գաղտնաբառը պետք է բաղկացած լինի առնվազն {0} նիշ',
};

export default errors;
