/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import {
  FailResult as IFailResult,
  SuccessResult as ISuccessResult,
} from '@/types';

class SuccessResult<T> implements ISuccessResult<T> {
  constructor(public readonly value: T) {
    Object.freeze(this);
  }

  isSuccess(): this is SuccessResult<T> {
    return true;
  }

  isFailure(): false {
    return false;
  }
}

class FailResult<E> implements IFailResult<E> {
  constructor(public readonly error: E) {
    Object.freeze(this);
  }

  isSuccess(): false {
    return false;
  }

  isFailure(): this is FailResult<E> {
    return true;
  }
}

export default class Result {
  static ok<T>(value: T) {
    return new SuccessResult(value);
  }

  static fail<E>(error: E) {
    return new FailResult(error);
  }
}
