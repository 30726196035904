import { Errors } from '@/types';

const errors: Errors = {
  50.0004: 'მოქმედებების დაუშვებელი თანმიმდევრობა',
  50.0005: 'პარამეტრები არ არსებობს ან ბათილია',
  50.0007: 'კოდის გამოყენების ძალიან ბევრი მცდელობა',
  50.0008: 'არასწორი ლოგინი ან პაროლი',
  50.0012: 'არასწორი კოდი',
  50.0013: 'პაროლი უნდა შეიცავდეს მინიმუმ {0} სიმბოლოს',
};

export default errors;
