/* eslint-disable import/prefer-default-export */
import { Vue, Component } from 'vue-property-decorator';
import { pds } from '@/api';
import { I18n } from '@/i18n/i18n';
import { ErrorResponse, Pages } from '@/types';
import { Router } from '@/router/router';
import { authorization } from '@/helpers/authorization';
import { Store } from '@/store/store';
import { appSession } from '@/helpers/session';

export { Component } from 'vue-property-decorator';

@Component
export class BaseComponent extends Vue {
  error: ErrorResponse | null = null;

  pds = pds;

  store = new Store();

  router = new Router(this.store);

  authorization = authorization;

  i18n = new I18n(this.store);

  Pages = Pages;

  session = appSession;

  readonly publicPath = process.env.VUE_APP_BASE as string;
}
