







import { I18n } from '@/i18n/i18n';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  i18n = new I18n();
}
