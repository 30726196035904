/* eslint-disable import/prefer-default-export */
import { Store } from '@/store/store';
import { AuthorizationData } from '@/types';
import { appSession } from './session';

export class Authorization {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private store: Store = new Store(),
    private session = appSession,
  ) {
    this.session.subscribe((state) => {
      if (state.client) {
        this.setClientData(state.client);
      }
    });
  }

  getClientId() {
    return this.store.getClientId();
  }

  setClientId(clientId: string) {
    this.store.setClientId(clientId);
  }

  check() {
    return this.store.getSettings() !== null;
  }

  setClientData(settings: AuthorizationData) {
    this.store.setSettings(settings);
  }

  getClientData() {
    return this.store.getSettings();
  }
}

export const authorization = new Authorization();
