import { Errors } from '@/types';

const errors: Errors = {
  50.0004: 'Иш-аракеттердин жол берилгис ырааттуулугу',
  50.0005: 'Параметрлер жок же жараксыз',
  50.0007: 'Кодду колдонуу аракети өтө көп',
  50.0008: 'Туура эмес логин же сырсөз',
  50.0012: 'Код туура эмес',
  50.0013: 'Сырсөз кеминде {0} белгиден турушу керек',
};

export default errors;
